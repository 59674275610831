<template lang="pug">

footer.footer
  BackgroundFigure.footer__figure(:name="'5'")

  .container.footer__container
    .footer__content
      .footer__title DSTRCT ———— interior
      .footer__date //{{ yearStart }} — {{ yearNow }}

    .footer__dev
      | Разработка сайта — 
      a(href="https://artica.art/" rel='noopener' target='_blank')
        svg(viewBox='0 0 74 15' fill='currentColor')
          path(d='M42.4491 5.10225L40.8535 5.1062L40.8776 14.8511L42.4733 14.8471L42.4491 5.10225Z')
          path(d='M50.682 5.03761C49.369 5.04084 48.1351 5.55516 47.2089 6.48638C46.2828 7.41714 45.7741 8.65304 45.7774 9.96661C45.7806 11.2797 46.2954 12.5131 47.2261 13.4397C48.1569 14.3659 49.3933 14.8741 50.7064 14.8708C52.1361 14.8673 53.4864 14.2431 54.4207 13.1563L53.2439 12.46C52.568 13.1482 51.6669 13.5284 50.703 13.5308C48.7308 13.5357 47.1227 11.935 47.1178 9.96329C47.113 7.99107 48.7131 6.38296 50.6853 6.37809C51.6492 6.37569 52.5522 6.75101 53.2315 7.43634L54.4048 6.73414C53.4652 5.65157 52.1118 5.03407 50.682 5.03761Z')
          path(d='M55.834 14.5723L57.7509 14.5675L64.3502 3.4162L64.3897 3.34947L64.4291 3.416L71.0839 14.5345L73.0004 14.5298L64.3817 0.128906L55.834 14.5723Z')
          path(d='M23.5886 10.4929L23.6509 10.4795C24.9142 10.2056 25.828 9.07077 25.8248 7.78085C25.8211 6.26224 24.5829 5.02971 23.0643 5.03346L16.9043 5.04871L17.7668 6.44424L23.1013 6.43103C23.8469 6.42919 24.4546 7.04476 24.4565 7.80361C24.4584 8.56245 23.8537 9.18103 23.1081 9.18287L22.8312 9.18356L21.9292 9.18578L21.9327 10.5905L21.9328 10.626L24.4161 14.775L26.1488 14.7707L23.6213 10.5476L23.5886 10.4929Z')
          path(d='M29.002 5.01844L29.0056 6.48886L32.3811 6.4805L32.427 6.48038L32.4271 6.52621L32.4474 14.7548L34.0426 14.7509L34.0222 6.52226L34.0221 6.47643L34.0684 6.47632L37.444 6.46798L37.4403 4.99756L29.002 5.01844Z')
          path(d='M0.46875 14.7092L2.38521 14.7045L8.98495 3.55316L9.02448 3.48643L9.06338 3.55296L15.7182 14.6715L17.6347 14.6668L9.01651 0.265869L0.46875 14.7092Z')

</template>

<script>
import { mapState } from "vuex";

export default {
  setup() {
    const yearStart = 2021;
    return { yearStart };
  },
  computed: {
    ...mapState({
      date: state => state.route.data.date
    }),
    yearNow() {
      return new Date(this.date).getFullYear();
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    position: relative;
    color: var(--color-muted-3);
    padding: 0 0 50px 0;
    &__figure {
      width: 70%;
      right: 0;
      bottom: 0;
    }
    &__container {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
    }
    &__content {
      display: flex;
      align-items: center;
      gap: 0 50px;
    }
    &__dev {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 6px;
      a {
        transform: translateY(-0.1em);
        color: var(--color-accent-1);
        svg {
          display: block;
          width: 5em;
        }
        &[data-touch], &[data-hover] {
          color: #fff;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: 45px;
      &__figure {
        width: 100%;
      }
    }
    @include media-breakpoint-down(md) {
      &__container {
        flex-direction: column;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 35px;
      &__content {
        flex-wrap: wrap;
        gap: 15px 40px;
      }
      &__figure {
        width: 130%;
      }
    }
  }
</style>